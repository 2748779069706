.tab-element {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: Avenir;

  &:hover, &.selected {
    -webkit-text-stroke: 0.7px white
  }
  .title {
    color: var(--color-lighter-grey);
    
  }
}