@import 'assets/css/constants.scss';

* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F4F6FB;

    #app {
      height: 100vh;
      padding-top: $topbar-height;
      overflow-y: scroll;

      .page {
        display: flex;
        padding: 24px;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1256px;
        min-width: 1256px;
        .title {
          font-size: 24px;
          font-weight: 800;
        }
        .wrapper {
          background: white;
          border-radius: 20px;
          width: 100%;
          padding: 30px;
          .title {
            font-size: 20px;
            font-weight: 800;
            position: relative;
          }
        }
      }
    }
  }
}
