@import 'assets/css/constants.scss';

.loader {
  .spinner {
    border-radius: 50%;
    background: conic-gradient(#0000 10%,$color-main);
    animation: spinner-animation 1s infinite linear;
  }

  &.normal .spinner {
    width: 56px;
    height: 56px;
    mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
  }

  &.small .spinner {
    width: 36px;
    height: 36px;
    mask: radial-gradient(farthest-side,#0000 calc(100% - 6px),#000 0);
  }

  &.large .spinner {
    width: 76px;
    height: 76px;
    mask: radial-gradient(farthest-side,#0000 calc(100% - 12px),#000 0);
  }

  &.standalone {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  @keyframes spinner-animation {
      to {
        transform: rotate(1turn);
      }
  }
}