.chip {
  border-radius: 6px;
  
  &.pending {
    background-color: #f1dfc6;
    color: #bd5601;
    width: 100%;
    padding-left: 13px;
    .icon {
      color: #bd5601;
    }
  }

  &.granted {
    background-color: #d7e7d3;
    color: #2c6c26;
    width: 100%;
    padding-left: 13px;
    .icon {
      color: #2c6c26;
    }
  }
}

/// Demi journée ///
.chip.morning {
  clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0% 100%);
}

.chip.afternoon {
  clip-path: polygon(100% 100%, 100% 0, 30px 0, 0% 100%);

}

.chip.afternoonMorning {
  clip-path: polygon(calc(100% - 30px) 100%, 100% 0, 30px 0, 0% 100%);
}