.popper {
  z-index: 3;
}

.agenda-table-container{
  padding-top: 5px;
  overflow: visible;
  z-index: 1;
  .cell {
    font-size: 14px;
    font-weight: 800;
    padding: 0;
  }
  .user-info-container {
    display: flex;
    align-items: center;
    width: 180px;
    min-height: 45px;
    .avatar{
      width: 37px;
      height: 37px;
      margin-right: 8px;
      font-size: 14px;
    }
    .user-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 130px;
      .team-name {
        display: block;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
}

.absence-table-cell {
  overflow-x: visible;
  .user-absences-container {
    display: flex; 
    justify-content: space-between;
    .absence-date {
      min-width: 30px;
      position: relative;
      .indicator {
        font-size: 12px;
        position: absolute;
        color: #8f8f8f;
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%);
      }
      .absence-indicator {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 40%;
        transform: translate(0%, -50%);
        border-radius: 6px;
      }
    }
  }
}