.agenda-grid-container {
  .header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 5;
    margin-bottom: 5px;
    .paper {
      box-shadow: 0px 21px 47px -34px #00000040;
      padding-bottom: 10px;
    }
  }

  .filter-and-calendar-container {
    .team {
      .select {
        width: 151px;
        height: 40px;
        border-radius: 6px;
      }
    }
  }

  .calendar-grid-container {
    .days-of-month-container {
      justify-content: space-between;
      .day {
        min-width: 27px;

        .day-of-week {
          font-size: 20px;
          font-weight: 800;
          text-align: center;
          display: block;
          color: inherit;
        }

        &.weekend {
          .day-of-week, .date-number {
            color: #8F8F8F;
          }
        }

        .date-number {
          text-align: center;
          display: block; 
          color: inherit;
          font-size: 0.87rem;
        }
      }
    }
  }  
}