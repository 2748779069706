/* * * * COLORS * * * */
$color-main: #4d32e2;
$color-secondary: #F4F3F7;

/* * * * SIZES * * * */
$topbar-height: 72px;

.d-flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.justify-content-center {
	justify-content: center;
}

.align-items-center {
	align-items: center;
}

.mt-24 {
	margin-top: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.ml-24 {
	margin-left: 24px;
}

.gap-24 {
	gap: 24px;
}